import Vue from 'vue'

import axios from 'axios'

// Environment
import environment from '@core/environments/environment'

const axiosIns = axios.create({
	// You can add your headers here
	// ================================
	baseURL: environment.endPoint,
	// timeout: 1000,
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Content-Type': 'application/json',
		Accept: 'application/json',
	},
})

Vue.prototype.$http = axiosIns
export default axiosIns
